// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-car-1-js": () => import("./../src/pages/car1.js" /* webpackChunkName: "component---src-pages-car-1-js" */),
  "component---src-pages-car-2-js": () => import("./../src/pages/car2.js" /* webpackChunkName: "component---src-pages-car-2-js" */),
  "component---src-pages-car-3-js": () => import("./../src/pages/car3.js" /* webpackChunkName: "component---src-pages-car-3-js" */),
  "component---src-pages-car-4-js": () => import("./../src/pages/car4.js" /* webpackChunkName: "component---src-pages-car-4-js" */),
  "component---src-pages-car-list-js": () => import("./../src/pages/car-list.js" /* webpackChunkName: "component---src-pages-car-list-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-maintain-js": () => import("./../src/pages/maintain.js" /* webpackChunkName: "component---src-pages-maintain-js" */),
  "component---src-pages-repair-js": () => import("./../src/pages/repair.js" /* webpackChunkName: "component---src-pages-repair-js" */),
  "component---src-pages-thanks-js": () => import("./../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */)
}

